<template>
  <div
    v-if="leftTime"
    id="time-limit"
    class="te-time-limit co-letter-spacing-spread te-bg-time-limit te-bg-time-limit-banner flex flex-col items-center px-4 py-5 text-center"
  >
    <b class="co-display-10 font-normal text-co-yellow-500">
      <span class="block pr-2 font-bold sm:inline sm:pr-0">割引特典</span>
      受講料
      <span class="co-display-6 font-bold">10,000</span>
      円引き
      <div
        class="te-time-limit-tooltip tooltip tooltip-bottom p-0 pl-2"
        data-tip="有効期限までにコースをお申し込みいただくと受講料が10,000円割引に！無料相談、無料体験、無料説明会動画、無料セミナー動画にお申し込みいただいたときと同じメールアドレスでお申し込みください。"
      >
        <button class="text-[1.25rem]"><i
          class="fa fa-question-circle-o"
          aria-hidden="true"
        /></button>
      </div>
      <div class="co-display-13 mb-2 text-co-gray-50">※学生は5,000円引き</div>
    </b>
    <b class="co-display-10 bg-co-gray-950/20 px-6 py-2 align-baseline font-bold text-co-gray-50 shadow">
      <span class="block sm:inline">
        <i
          class="fa fa-clock-o co-display-6 pr-2"
          aria-hidden="true"
        />
        有効期限まで残り
      </span>
      <span
        id="remaining-days-wrap"
        class="co-display-6 font-bold"
      >
        <span id="remaining-days">{{ formatTime(days) }}</span>
        日
      </span>
      <span
        id="remaining-hours"
        class="co-display-6 font-bold"
      >{{ formatTime(hours) }}</span>
      時間
      <span
        id="remaining-minutes"
        class="co-display-6 font-bold"
      >{{ formatTime(minutes) }}</span>
      分
      <span
        id="remaining-seconds"
        class="co-display-6 font-bold"
      >{{ formatTime(seconds) }}</span>
      秒
    </b>
  </div>
</template>

<script lang="ts">
import type { StringDate } from 'types/constantType'

interface Data {
  leftTime: number
}

interface Props {
  entryDiscountTimeLimit: StringDate
}

const options = {
  data(): Data{
    return {
      leftTime: 0,
    }
  },
  computed: {
    seconds():number {
      return Math.floor((this.leftTime / 1000) % 60)
    },
    minutes():number {
      return Math.floor((this.leftTime / 1000 / 60) % 60)
    },
    hours():number {
      return Math.floor((this.leftTime / (1000 * 60 * 60)) % 24)
    },
    days():number {
      return Math.floor(this.leftTime / (1000 * 60 * 60 * 24))
    },
  },
  props: {
    entryDiscountTimeLimit: {
      type: String,
    } as PropOptions<StringDate>,
  },
  mounted() {
    this.leftTime =
      Date.parse(this.entryDiscountTimeLimit) - new Date().getTime()
    setTimeout(this.countdown, 1000)
  },
  methods: {
    countdown(): void {
      this.leftTime -= 1000
      if (this.leftTime > 0) {
        setTimeout(this.countdown, 1000)
      } else {
        this.leftTime = 0
      }
    },
    formatTime(value: number): string {
      if (value < 10) {
        return '0' + value;
      }
      return value.toString();
    },
  },
}

export default defineNuxtComponent(options)
</script>

<style scoped>
.te-bg-time-limit-banner {
  background-color: #ff802b;
  background-repeat: no-repeat;
  background-position: 3% center, 97% center;
  background-size: auto, auto;
  @media (min-width: 768px) {
    background-image: url('~/assets/images/Te/TeCommon/Banner/Timelimit/bg-countdown-1.png'),
      url('~/assets/images/Te/TeCommon/Banner/Timelimit/bg-countdown-2.png');
  }
}

/* ツールチップ */
.tooltip::before {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem;
}

.tooltip-bottom::before {
  transform: translateX(-70%);
}

.tooltip-bottom::after {
  border-color: transparent transparent #000;
}
</style>
