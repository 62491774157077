<template>
  <div class="bg-co-success-500">
    <div class="co-container-md flex flex-col items-center p-4 lg:flex-row xl:px-0">
      <img
        class="mr-8"
        src="~/assets/images/Te/TeCommon/Settlement/settlement-banner-1.png"
        alt="早めのご入金がお得！！！"
        width="196"
        height="102"
        loading="lazy"
      >
      <div class="mb-4 text-center text-co-gray-50 lg:mb-0 lg:text-left">
        <p class="mb-4">
          お申し込みされたコースへご入金ください。<span class="inline-block border-b-2 border-co-yellow-500">ご入金後すぐにカリキュラムを閲覧して予習できるようになります。</span>
        </p>
        <p class="mb-0">
          入金期限：{{ entrySettlementTimeLimit }}
        </p>
      </div>
      <a
        class="cursor-pointer text-nowrap rounded bg-co-gray-950/20 px-4 py-2 text-[0.875rem] text-co-gray-50 hover:bg-co-gray-950/20 hover:text-co-gray-50"
        :href="entryPaymentUrl"
      >入金について確認する</a>
    </div>
  </div>
</template>

<script lang="ts">

const options = {
  computed: {
    formattedTime(): string {
      if (!this.entrySettlementTimeLimit) return '-'
      const dt = new Date(this.entrySettlementTimeLimit)
      const year = dt.getFullYear()
      const month = dt.getMonth() + 1
      const day = dt.getDate()
      const dayOfWeek = ['日', '月', '火', '水', '木', '金', '土'][dt.getDay()]
      return `${year}年${month}月${day}日（${dayOfWeek}）`
    },
  },
  props: {
    entrySettlementTimeLimit: {
      type: String,
      default: null,
    } as PropOptions<StringDate>,
    entryPaymentUrl: {
      type: String,
      default: '',
    },
  },
}

export default defineNuxtComponent(options)
</script>
